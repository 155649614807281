<template>
  <div class="index_wrap" >
    <img src="./img/bg.png" class="bg_img" />
    <div class="header_wrap" >
      <img src="./img/logo2.png" class="logo_img" />
      <img src="./img/scan.png" class="scan_img" @click="handleScan" />
    </div>

    <div class="welcome_wrap" v-if="false" >
      <div class="welcome_text" >
        <div>欢迎来到</div>
        <div class="line" ></div>
      </div>
      <div class="welcome_desc" >宫廷博士AI熏蒸舱</div>
    </div>

    <div class="user_wrap" v-if="!isComplete" @click="toUser" >
      <img src="./img/qrcode.png" class="qrcode_img" @click.stop="showQrCode" />
      <div class="user_content" >
        <img src="./img/user.png" class="user_img" />
        <div class="phone_text" >{{ user.phone }}</div>
      </div>
      <div class="edit_wrap" >
        <div class="edit_left" >
          <img src="./img/edit.png" class="edit_icon" />
          请完善个人信息
        </div>
        <div class="edit_button" >去完善</div>
      </div>
    </div>

    <div class="user_real_wrap" v-else @click="toUser" >
      <img src="./img/qrcode.png" class="qrcode_img" @click.stop="showQrCode" />
      <img src="./img/11.png" class="user_img" v-if="user.sex == 0" />
      <img src="./img/12.png" class="user_img" v-if="user.sex == 1" />
      <div class="user_name" >{{ user.name }}</div>
      <div class="user_phone" >{{ user.phone }}</div>
    </div>
    <div class="score_wrap" @click="toShopping" >
      <div class="score_title" >我的积分</div>
      <div class="score_bottom" >
        <div class="score_text" >
          {{ user.score }} <span>分</span>
        </div>
        <div class="enter_wrap" >
          积分商城
          <img src="./img/19.png" class="enter_icon" />
        </div>
      </div>
    </div>
    <div class="opt_wrap" >
      <div class="opt_left" >
        <div class="my_shop_wrap" @click="toPage('shop')" >
          <div class="title">
            <img src="./img/1.png" class="shop_icon" />
            <div class="name" >我的门店</div>
            <div class="shop_desc" >My SHOP</div>
          </div>
          <img src="./img/2.png" class="enter_icon" />
          <img src="./img/3.png" class="shop_img" />
        </div>

        <div class="my_order_wrap" @click="toPage('order')" >
          <div class="title">
            <img src="./img/4.png" class="shop_icon" />
            <div class="name" >我的订单</div>
            <div class="order_desc" >My ORDER</div>
          </div>
          <img src="./img/2.png" class="enter_icon" />
          <img src="./img/6.png" class="order_img" />
        </div>
      </div>

      <div class="opt_left" >
        <div class="report_wrap" @click="toPage('reportList')" >
          <div class="title">
            <img src="./img/7.png" class="report_icon" />
            <div class="name" >健康报告</div>
            <div class="desc" >REPORT</div>
          </div>
          <img src="./img/2.png" class="enter_icon" />
          <img src="./img/8.png" class="report_img" />
        </div>

        <div class="coupon_wrap" @click="toPage('couponList')" >
          <div class="title" >
            <img src="./img/15.png" class="coupon_icon" />
            <div class="name" >优惠券</div>
            <div class="desc" >COUPON</div>
          </div>
          <img src="./img/16.png" class="enter_icon" />
          <img src="./img/17.png" class="coupon_img" />
        </div>
      </div>

      <div class="bottom_wrap" >
        <van-button class="logout_button" @click="logout" >退出登录</van-button>
      </div>

      <van-popup v-model="show">
        <div style="width: 260px; height: 260px; display: flex; align-items: center; justify-content: center" @click="show = false" >
          <div id="qrcode" style="width: 240px; height: 240px" >

          </div>
        </div>
      </van-popup>

    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getCustomerVo,
  getScore
} from '@/api'
import {
  getSign
} from '@/api/device'
import wx from 'weixin-js-sdk'
import QRCode from 'qrcodejs2'
import { Dialog } from 'vant'

export default {
  name: 'index',
  data() {
    return {
      user: {
        score: 0
      },
      isComplete: true,
      show: false,
    }
  },
  methods: {
    toShopping() {
        // window.location.href = 'http://test30.beibianjk.com/score/#/scoreShopping/pages/score/index?token=' + localStorage.getItem('token')
        window.location.href = 'http://ainlc.beibianjk.com/score/#/scoreShopping/pages/score/index?token=' + localStorage.getItem('token')
    },
    logout() {
      Dialog.confirm({
        title: '提示',
        message: '是否退出登录？',
        confirmButtonColor: '#D32D1C',
      })
        .then(() => {
          localStorage.clear()
          window.location.reload()
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    showQrCode() {
      this.show = true
      this.$nextTick(() => {
        this.generateQrcode()
      })
    },
    generateQrcode() {
      const { customerId } = this.user
      document.getElementById('qrcode').innerHTML = ''
      new QRCode(document.getElementById('qrcode'), {
        text: customerId,
        width: 240,
        height: 240
      })
    },
    handleScan() {
      const that = this
      console.log('wx:', wx)
     /* wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          console.log(res)
          let url = res.resultStr
          let sn = (url.split('?')[1]).split('=')[1]
          console.log('sn:', sn)
          that.$router.push({
            name: 'pay',
            query: {
              sn
            }
          })
        },
        fail: function (res) {
          console.log('res:', res)
        }
      });*/
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: [ 'scanQRCode'],
          success: function (res) {
            console.log('res:', res)
            if (res.checkResult.scanQRCode === true) {
              wx.scanQRCode({
                needResult: 1,
                scanType: ['qrCode'],
                success: function (res) {
                  let url = res.resultStr
                  let sn = (url.split('?')[1]).split('=')[1]
                  console.log('sn:', sn)
                  that.$router.push({
                    name: 'pay',
                    query: {
                      sn
                    }
                  })
                },
                fail: function (res) {
                  console.log('fail', res)
                }
              })
            } else {
              console.log('抱歉，当前用户端版本不支持扫一扫，请升级到最新版')
            }
          },
          fail: function (res) {
            console.log('fail', res)
          }
        })
      })
    },
    toPage(name) {
      this.$router.push({
        name
      })
    },
    toUser() {
      this.$router.push({
        name: 'user'
      })
    },
    getInfo() {
      getCustomerVo({}).then(res => {
        if(res.success) {
          this.user = res.data
          console.log({...this.user})
          if(!res.data.sex) {
            this.isComplete = false
          }
        }
      })
    },
    getSign() {
      getSign({url: !sessionStorage.getItem('originUrl') ? window.location.href : sessionStorage.getItem('originUrl')}).then(res => {
        if(res.success) {
          if(res.success) {
            let params = res.data
            wx.config({
              debug: false, // 生产环境需要关闭debug模式
              appId: params.appId, // appId通过微信服务号后台查看
              timestamp: params.timestamp, // 生成签名的时间戳
              nonceStr: params.nonceStr, // 生成签名的随机字符串
              signature: params.signature, // 签名
              jsApiList: [ // 需要调用的JS接口列表
                'scanQRCode',
              ],
              success(res) {
                console.log('res:', res)
              },
              fail(res) {
                console.log('fail:', res)
              }
            })
          }
        }
      })
    },
  },
  mounted() {
    this.getSign()
    this.getInfo()
  }
}
</script>
